import { Controller } from "@hotwired/stimulus";

/*
 * Aborta todas las peticiones pendientes cuando estamos por cambiar de
 * página.
 *
 * <body data-controller="abort"
 * data-action="beforeunload@window->abort#abort
 * turbolinks:before-visit@window->abort#abort">
 */
export default class extends Controller {
  connect() {
    window.abortController = new AbortController();
  }

  /*
   * Al lanzar el evento de abortar, cancelamos todas las peticiones
   * pendientes.
   */
  abort(event = undefined) {
    console.log("Vamos a abortar todas las peticiones pendientes!");
    window.abortController.abort();
  }
}
