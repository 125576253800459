import SuttyEditor from "@suttyweb/editor";

import "@suttyweb/editor/dist/style.css";

document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll(".new-editor").forEach((editorContainer) => {
    new SuttyEditor({
      target: editorContainer,
      props: {
        textareaEl: editorContainer.querySelector("textarea"),
      },
    });
  });
});
