import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.originalFormData = new FormData(this.element);
    this.originalFormDataSerialized = this.serializeFormData(this.originalFormData);
    this.submitting = false;
  }

  submit(event) {
    this.submitting = true;
  }

  unsaved(event) {
    if (this.submitting) return;
    if (!this.hasChanged()) return;

    this.submitting = false;

    event.preventDefault();

    event.returnValue = true;
  }

  unsavedTurbolinks(event) {
    if (this.submitting) return;
    if (!this.hasChanged()) return;

    this.submitting = false;

    if (window.confirm(this.element.dataset.unsavedChangesConfirmValue)) return;

    event.preventDefault();
  }

  serializeFormData(formData) {
    formData.delete("authenticity_token");

    return (new URLSearchParams(formData)).toString();;
  }

  hasChanged() {
    return (this.originalFormDataSerialized !== this.serializeFormData(new FormData(this.element)));
  }
}
